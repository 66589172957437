import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { disableEnter } from '@utils/helpers';

import Text from '@components/text';

import EmailBorder from '@images/contact/field-email.svg';
import MessageBorder from '@images/contact/field-message.svg';

const InputStyles = css`
  ${tw`bg-transparent border-none resize-none focus:outline-none`}
  
  &, ::placeholder { 
    line-height: 1.7;
    letter-spacing: 0.18px; 
    ${tw`text-16 text-primary`}
  }
`;

const Label = tw(Text)`mb-15 pl-5`;

const Field = styled.div`
  ${tw`relative w-300 mb-15 sm:w-480 lg:w-460 xl:w-550`}
  svg { ${tw`absolute -z-1 top-35 w-full`} }

  &.message { 
    ${tw`mt-25`} 
    svg { ${tw`h-280`} }
  }
`;

const Email = styled.textarea`
  scrollbar-width: none;
  -ms-overflow-style: none;
  ${tw`relative left-40 w-4/5 h-60 py-15 overflow-x-scroll overflow-y-hidden whitespace-nowrap`}
  ${InputStyles}

  ::-webkit-scrollbar { ${tw`w-0 h-0`} }
`;

const TextArea = styled.textarea`${tw`w-full mt-5 px-40 pt-15 pb-50`} ${InputStyles}`;

const ErrorBorder = styled.div`svg .stroke { ${tw`stroke-error`} }`;

const Error = styled(Text)`
  ${tw`absolute text-error`}
  ${({ moved }) => (moved ? tw`-bottom-12 left-35` : tw`-bottom-10 left-40`)}
`;

const Inputs = ({ register, errors }) => {
  const intl = useIntl();
  return (
    <>
      <Field>
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.e-mail" />
        </Label>
        <Email
          placeholder={intl.formatMessage({ id: 'form.placeholders.e-mail' })}
          type="text"
          rows="1"
          name="email"
          onKeyDown={disableEnter}
          ref={register({
            required: intl.formatMessage({ id: 'form.errors.required' }),
            pattern: {
              value: /^\S+@\S+$/i,
              message: intl.formatMessage({ id: 'form.errors.e-mail' }),
            },
          })}
        />
        { errors.email ? <ErrorBorder><EmailBorder /></ErrorBorder> : <EmailBorder />}
        { errors.email && <Error fontSizes={12}>{errors.email.message}</Error> }
      </Field>
      <Field className="message">
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.message" />
        </Label>
        <TextArea
          placeholder={intl.formatMessage({ id: 'form.placeholders.message' })}
          type="text"
          name="message"
          rows="8"
          ref={register({ required: intl.formatMessage({ id: 'form.errors.required' }) })}
        />
        { errors.message ? <ErrorBorder><MessageBorder /></ErrorBorder> : <MessageBorder />}
        { errors.message && <Error moved fontSizes={12}>{errors.message.message}</Error> }
      </Field>
    </>
  );
};

Inputs.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Inputs;
