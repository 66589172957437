import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import DefaultLink from '@components/link';

const Wrapper = tw.div`relative`;

const Data = styled.div`
  ${tw`w-160 mx-auto sm:mx-0`}
  ${({ law }) => law && tw`mt-20`}
`;

const Link = tw(DefaultLink)`inline-block`;

const LinkDistanced = tw(Link)`mt-15`;

const TextHover = tw(Text)`transition-colors duration-300 hover:text-redDark`;

const Content = ({ data }) => (
  <Wrapper>
    {data.address && (
      <Data>
        <Text fontSizes={16} fontWeight={300} lineHeight={1.5}>
          {data.address}
        </Text>
      </Data>
    )}
    {data.law && (
      <Data law>
        <Text fontSizes={16} fontWeight={300} lineHeight={1.5}>
          {data.law}
        </Text>
      </Data>
    )}
    {data.phone && (
      <Link to={`tel:${data.phone}`} isExternal>
        <TextHover fontSizes={16} fontWeight={300} lineHeight={1.5}>
          {data.phone}
        </TextHover>
      </Link>
    )}
    {data.email && (
      <Link to={`mailto:${data.email}`} isExternal>
        <Text fontSizes={16} fontWeight={300} lineHeight={1.5}>
          {data.email}
        </Text>
      </Link>
    )}
    {data.link && (
      <LinkDistanced to={data.link} hash={data.hash}>
        <Text fontSizes={16} fontWeight={600} lineHeight={1.5} underline uppercase>
          <FormattedMessage id="contact.contact us" />
        </Text>
      </LinkDistanced>
    )}
  </Wrapper>
);

Content.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Content;
