import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Content from '@components/contact/details/content';

const Wrapper = tw.div`text-center sm:w-1/2 sm:text-left`;

const Subitem = styled.div`
  ${tw`mb-35`}
  ${({ title }) => title === 'investors' && tw`sm:mt-44`}
`;

const Title = tw(Text)`mb-2`;

const Item = ({ subitems }) => (
  <Wrapper>
    {subitems.map(({ title, data }) => (
      <Subitem key={title} title={title}>
        <Title
          fontSizes={16}
          fontWeight={600}
          letterSpacing={20}
          lineHeight={1.3}
        >
          <FormattedMessage id={`contact.${title}`} />
        </Title>
        <Content data={data} />
      </Subitem>
    ))}
  </Wrapper>
);

Item.propTypes = {
  subitems: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ])),
  ).isRequired,
};

export default Item;
