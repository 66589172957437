import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';

import Data from '@utils/contact';
import Item from '@components/contact/details/item';

const Wrapper = tw.div`
  box-border relative pt-35
  sm:flex sm:flex-wrap sm:content-start sm:pt-50 sm:pb-25 sm:pl-40
  lg:w-1/2 lg:pt-0 lg:pl-0
`;

const Concrete = styled(Img)`
  top: 0;
  right: -190px;
  ${tw`absolute! -z-1 w-600 sm:w-680 md:w-800 lg:hidden`}

  @media (min-width: 640px) { right: -140px; }

  @media (min-width: 768px) { right: -290px; }
`;

const Details = () => {
  const data = useStaticQuery(graphql`
    query {
      concrete: file(relativePath: { eq: "concrete.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Concrete fluid={data.concrete.childImageSharp.fluid} />
      {Data.map((boxes) => <Item key={boxes[0].title} subitems={boxes} />)}
    </Wrapper>
  );
};

export default Details;
