import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';

const Button = tw(ButtonDefault)`block mx-auto mt-40 mb-30 sm:mb-50 lg:mx-0 lg:mt-30 lg:mb-0`;

const Submit = () => (
  <Button type="submit">
    <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
      <FormattedMessage id="form.submit" />
    </Text>
  </Button>
);

export default Submit;
