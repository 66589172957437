import React from 'react';
import tw from 'twin.macro';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import LayoutConcrete from '@layouts/concrete';
import SEO from '@components/seo.jsx';
import Text from '@components/text.jsx';
import Form from '@components/contact/form';
import Details from '@components/contact/details';

const Container = tw.div`max-w-xs mx-auto pt-70 sm:max-w-480 lg:max-w-full lg:mb-40 lg:pt-0`;

const Wrapper = tw.div`lg:flex lg:flex-row-reverse`;

const Title = tw(Text)`mb-25 text-center lg:text-left`;

const Contact = () => {
  const intl = useIntl();

  return (
    <LayoutConcrete moved>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.contact' })}
        description={intl.formatMessage({ id: 'meta tags.description.contact' })}
      />
      <Container>
        <Title
          color="black"
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={600}
          lineHeight={1.15}
          letterSpacing={[53, 70]}
        >
          <FormattedMessage id="contact.title" />
        </Title>
        <Wrapper>
          <Form />
          <Details />
        </Wrapper>
      </Container>
    </LayoutConcrete>
  );
};

export default Contact;
