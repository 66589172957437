import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = styled.div`
  ${tw`flex items-center relative pt-10 pb-5`}
  
  label:after {
    content: '';
    transform: scale(0) translateY(-50%); // don't use tw for transform - transition problem on Safari
    ${tw`absolute top-20 left-11 w-5 h-5 bg-brown rounded-full transition-all duration-300`}
  }

  [type="radio"]:checked + label:after {
    transform: scale(1) translateY(-50%);
  }
`;

const Image = tw(Img)`absolute! top-20 left-7 w-12 transform -translate-y-1/2 -z-1`;

const Hidden = tw.div`hidden`;

const Radio = tw.input`hidden`;

const RadioLabel = tw.label`block font-openSans font-400 text-primary px-35 cursor-pointer`;

const Error = tw(Text)`absolute -bottom-17 left-35 text-error`;

const Terms = ({ register, errors }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <fieldset id="termsAccepted">
        <Hidden>
          <Radio id="title" type="hidden" name="terms" ref={register({ required: true })} />
          <RadioLabel htmlFor="title">Terms Accepted</RadioLabel>
        </Hidden>
        <Radio
          id="terms"
          type="radio"
          name="terms"
          value="true"
          ref={register({ required: intl.formatMessage({ id: 'form.errors.terms' }) })}
        />
        <RadioLabel htmlFor="terms">
          <Text fontSizes={14} lineHeight={1.36} letterSpacing={18}>
            <FormattedHTMLMessage id="form.terms" />
          </Text>
        </RadioLabel>
        <Image fluid={data.mark.childImageSharp.fluid} />
        { errors.terms && <Error fontSizes={12}>{errors.terms.message}</Error> }
      </fieldset>
      <Text fontSizes={14} lineHeight={1.36} letterSpacing={18}>
        <FormattedHTMLMessage id="form.terms" />
      </Text>
    </Wrapper>
  );
};

Terms.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Terms;
