import React from 'react';
import { useForm } from 'react-hook-form';
import tw from 'twin.macro';
import sendData from '@utils/forms';

import Inputs from '@components/contact/form/inputs';
import Terms from '@components/contact/form/terms';
import Submit from '@components/contact/form/submit';
import Message from '@components/contact/form/message';

const Wrapper = tw.form`relative box-border px-5 lg:w-1/2 lg:pl-50`;

const Form = () => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Wrapper
      onSubmit={handleSubmit(sendData)}
      name="contact"
      method="post"
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="contact" ref={register} />
      <Inputs register={register} errors={errors} />
      <Terms register={register} errors={errors} />
      <br/>
      <div class="rodo_msg"><p>Wysłanie przez Ciebie wiadomości za pośrednictwem formularza kontaktowego jest jednoznaczne z wyrażeniem zgody na przetwarzanie Twoich danych osobowych przez Etno Cafe S.A. w celu udzielenia odpowiedzi na 
Twoje zapytanie.</p><br/>
<p>Pełna informacja o przysługujących Ci prawach w związku z przetwarzaniem Twoich danych osobowych, znajduje się w <a href="/polityka-prywatnosci">Polityce prywatności</a>.</p>
</div>

      <Submit />
      <Message />
    </Wrapper>
  );
};

export default Form;
